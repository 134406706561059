import { Slider } from "@mui/material";
import React, { useCallback } from "react";
import { withMediaProps } from "react-media-player";

const VolumeSlider: React.FC = (props: any) => {
    const { media } = props;

    const { volume } = media;

    const handleChange = useCallback(
        (e: Event, value: number) => {
            media.setVolume((+value).toFixed(4));
        },
        [media]
    );

    const widthString = "min(10%, 10rem)";    

    return (
        <Slider
            style={{ width: widthString, minWidth: widthString }}
            color="secondary"
            aria-label="Volume"
            value={volume}
            min={0}
            max={1}
            step={0.01}
            onChange={(e, v) => handleChange(e, v as number)}
        />
    );
};

export default withMediaProps(VolumeSlider);
