import { VolumeMute, VolumeUp } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import React, { useCallback } from "react";
import { withMediaProps } from "react-media-player";

const MuteUnmuteButton: React.FC = (props: any) => {
    const { media } = props;

    const handlePlayPause = useCallback(() => {
        media.muteUnmute();
    }, [media]);

    return (
        <IconButton onClick={handlePlayPause} color="secondary">
            {media.isMuted ? <VolumeMute /> : <VolumeUp />}
        </IconButton>
    );
};

export default withMediaProps(MuteUnmuteButton);
