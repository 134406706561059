import React, { createContext, useContext, useEffect, useState } from 'react';
import logo from './logo.svg';
import './App.css';

import { Box, Button, StyledEngineProvider } from '@mui/material';

import { createTheme, ThemeProvider, useTheme, useThemeProps } from '@mui/material/styles';
import NavigationScrollTop from './layout/NavigationScrollTop';
import AppRoutes from './routes/Routes';

import { BrowserRouter as Router } from 'react-router-dom';
import appTheme from './themes/appTheme';
import './assets/scss/style.scss';

import { getUserConfiguration } from './abp/getUserConfiguration';

import { getCurrentClockProvider, setLocalization } from './abp/utils';

import { Settings } from 'luxon';
import _ from 'lodash';
import config from './config';
import Authenticator from './data/authentication/Authenticator';
// const ColorModeContext = createContext({ toggleColorMode: () => {} });

export const AppContext = createContext<{
    appState: any;
    setAppState: React.Dispatch<any>;
    toggleColorMode: () => void;
}>({
    appState: {},
    setAppState: (x) => {},
    toggleColorMode: () => {},
});

enum LoadingState {
    NONE,
    LOADING,
    LOADED,
    ERROR,
}

const App: React.FC = () => {
    const theme = useTheme();
    const [configLoadingState, setConfigLoadingState] = useState(LoadingState.NONE);

    function loadConfig() {
        setConfigLoadingState(LoadingState.LOADING);

        getUserConfiguration()
            .then((result) => {
                _.merge(abp, result.data.result);
                // extend(true, abp, result.data.result);
                abp.clock.provider = getCurrentClockProvider(result.data.result.clock.provider);
                Settings.defaultLocale = abp.localization.currentLanguage.name;
                if (abp.clock.provider.supportsMultipleTimezone) Settings.defaultZone = abp.timing.timeZoneInfo.iana.timeZoneId;
                // signalRService.initSignalR();
                setLocalization();
                // loadLocalizationFiles();

                setConfigLoadingState(LoadingState.LOADED);
            })
            .catch((err) => {
                setConfigLoadingState(LoadingState.ERROR);
            });
    }

    useEffect(loadConfig, []);
    // const colorMode = useContext(ColorModeContext);

    const ENDPOINT_LOGIN = config.serviceUrl!.replace(/\/$/, '') + '/api/TokenAuth/Authenticate';

    return (
        // <Box
        //   sx={{
        //     display: "flex",
        //     width: "100%",
        //     alignItems: "center",
        //     justifyContent: "center",
        //     bgcolor: "background.default",
        //     color: "text.primary",
        //     borderRadius: 1,
        //     p: 3,
        //   }}
        // >
        //   {theme.palette.mode} mode
        //   <Button
        //     sx={{ ml: 1 }}
        //     onClick={colorMode.toggleColorMode}
        //     color="inherit"
        //   >
        //     {theme.palette.mode}
        //   </Button>
        // </Box>
        <Router>
            <NavigationScrollTop>
                <Authenticator authenticationUrl={ENDPOINT_LOGIN} refreshTokenUrl={''}>
                    <AppRoutes />
                </Authenticator>
            </NavigationScrollTop>
        </Router>
    );
};

export default function ContextApp() {
    // const [mode, setMode] = React.useState<'light' | 'dark'>('light');
    const [appState, setAppState] = React.useState<
        | {
              colorMode: 'light' | 'dark';
          }
        | any
    >({
        colorMode: 'light',
    });

    // const colorMode = React.useMemo(
    //     () => ({
    //         toggleColorMode: () => {
    //             setMode((prevMode) => (prevMode === 'light' ? 'dark' : 'light'));
    //         },
    //     }),
    //     []
    // );

    const appContext = React.useMemo(
        () => ({
            appState,
            setAppState,
            toggleColorMode: () => {
                setAppState((prev: any) => ({
                    ...prev,
                    colorMode: prev.colorMode === 'light' ? 'dark' : 'light',
                }));
            },
        }),
        [appState, setAppState]
    );

    const theme = React.useMemo(
        () =>
            appTheme({
                palette: {
                    mode: appState.colorMode,
                },
            }),
        [appState]
    );

    return (
        <AppContext.Provider value={appContext}>
            <StyledEngineProvider injectFirst>
                <ThemeProvider theme={theme}>
                    <App />
                </ThemeProvider>
            </StyledEngineProvider>
        </AppContext.Provider>
    );
}
