import * as React from "react";
import { SideNavItem } from "./NavItem";

const NavCollapse: React.FC<{ menu: SideNavItem; level: number }> = ({
  menu,
  level,
}) => {
  return <></>;
};

export default NavCollapse;
