import {
    Divider,
    List,
    Menu,
    MenuItem,
    Typography,
    useTheme,
} from '@mui/material';
import React, { useCallback } from 'react';
import NavCollapse from './NavCollapse';
import NavItem, { SideNavItem } from './NavItem';
import useContextMenu from '../../../../assets/hooks/useContextMenu';
import { NoteAdd } from '@mui/icons-material';

// export type SideNavMenu = {
//   id: any;
//   children: any;
//   type: "collapse" | "item";
// };

const NavGroup: React.FC<{ item: SideNavItem }> = ({ item }) => {
    const theme = useTheme();
    const { openContextMenu, menuProps } = useContextMenu();

    const items = item.children?.map((menu) => {
        switch (menu.type) {
            case 'collapse':
                return <NavCollapse key={menu.id} menu={menu} level={1} />;
            case 'item':
                return <NavItem key={menu.id} item={menu} level={1} />;
            default:
                return (
                    <Typography
                        key={menu.id}
                        variant="h6"
                        color="error"
                        align="center"
                    >
                        Menu Items Error
                    </Typography>
                );
        }
    });

    const handleAddDocument = useCallback(() => {
        menuProps.onClose();
    }, [menuProps]);
    
    const handleEditFolder = useCallback(() => {
        menuProps.onClose();
    }, [menuProps]);

    return (
        <>
            <List
                onContextMenu={openContextMenu}
                subheader={
                    item.title && (
                        <Typography
                            variant="caption"
                            sx={{ ...(theme.typography as any).menuCaption }}
                            display="block"
                            gutterBottom
                        >
                            {item.title}
                            {item.caption && (
                                <Typography
                                    variant="caption"
                                    sx={{
                                        ...(theme.typography as any)
                                            .subMenuCaption,
                                    }}
                                    display="block"
                                    gutterBottom
                                >
                                    {item.caption}
                                </Typography>
                            )}
                        </Typography>
                    )
                }
            >
                <>
                    {items}
                    <Menu {...menuProps}>
                        <MenuItem onClick={handleAddDocument}>
                            <NoteAdd />
                            {/* <Divider orientation='vertical' variant='middle' flexItem/> */}
                            Neues Dokument hinzufügen
                        </MenuItem>
                        <MenuItem onClick={handleEditFolder}>
                            <NoteAdd />
                            {/* <Divider orientation='vertical' variant='middle' flexItem/> */}
                            Neues Dokument hinzufügen
                        </MenuItem>
                    </Menu>
                </>
            </List>

            {/* group divider */}
            <Divider sx={{ mt: 0.25, mb: 1.25 }} />
        </>
    );
};

export default NavGroup;
