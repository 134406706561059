import React from "react";
import { Media, Player } from "react-media-player";
import "./MediaPlayer.scss";
import PlayPauseButton from "./PlayPauseButton";
import MuteUnmuteButton from "./MuteUnmuteButton";
import VolumeSlider from "./VolumeSlider";
import { Stack } from "@mui/material";
import SeekBarSlider from "./SeekBarSlider";

type MediaPlayerProps = {
    src: string;
};

const MediaPlayer: React.FC<MediaPlayerProps> = ({ src }) => {
    return (
        <Media>
            <div className="media-player-wrapper">
                <Stack
                    className="media-player"
                    alignItems="center"
                    justifyContent="center"
                >
                    <Player src={src} />
                </Stack>
                <div className="media-controls">
                    <Stack
                        direction={"row"}
                        className="flex-1"
                        alignItems="center"
                    >
                        <PlayPauseButton />
                        <MuteUnmuteButton />
                        <VolumeSlider />
                        <SeekBarSlider />
                    </Stack>
                </div>
            </div>
        </Media>
    );
};

export default MediaPlayer;
