import React, { PropsWithChildren } from "react";
import { Navigate, Outlet, Route, Routes, useRoutes } from "react-router-dom";
import AuthenticationRoutes from "./AuthenticationRoutes";
import MainRoutes from "./MainRoutes";
import ProtectedRoute from "../assets/components/routing/ProtectedRoute";
import Home from "../views/home/Home";
import MainLayout from "../layout/main-layout/MainLayout";
import Document from "../layout/document/Document";
import Login from "../views/login/authentication/Login";

// const LeafComponent: React.FC<PropsWithChildren<{}>> = ({ children }) => {
//   return <>{children}</>;
// };

const AppBase: React.FC = () => {
  return (
    <>
      <Outlet />
    </>
  );
};

const AppRoutes: React.FC = () => {
  // TODO Add AuthenticationRoutes here
  return (
    <>
      <Routes>
        <Route path="/" element={<AppBase />}>
          <Route index element={<Navigate to="/docs" />} />
          <Route path="login" element={<Login />} />
          <Route path="view/:secret" element={<MainLayout />}>
            {/* <Route path=":secret"> */}
              <Route index element={<Home />} />
              <Route path="docs">
                {/* <Route index element={<Navigate to="/" />} /> */}
                <Route path=":projectUrl/*" element={<Document />} />
              </Route>
            {/* </Route> */}
          </Route>
          <Route
            index
            path="/*"
            element={
              <ProtectedRoute
                redirectTo={`/login?returnUrl=${encodeURIComponent("/")}`}
              >
                <Routes>
                  <Route path="docs" element={<MainLayout />}>
                    <Route index element={<Home />} />
                    {/* <Route index element={<Navigate to="/" />} /> */}
                    <Route path=":projectUrl/*" element={<Document />} />
                  </Route>
                </Routes>
              </ProtectedRoute>
            }
          />
          {/* <Route
                        path="/"
                        element={<ProtectedRoute redirectTo={`/login?returnUrl=${encodeURIComponent('/')}`}>{<MainRoutes />}</ProtectedRoute>}
                    /> */}
        </Route>
      </Routes>
      {/* {useRoutes([AuthenticationRoutes])} */}
    </>
  );
};

export default AppRoutes;
