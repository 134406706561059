import React, {
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react';
import { Link, useParams } from 'react-router-dom';
import { AppContext } from '../../App';
import useSWR from 'swr';
import { argsFetcher, updateItem } from '../../data/fetcher';
import {
    Box,
    Container,
    Divider,
    Drawer,
    Grid,
    IconButton,
    LinearProgress,
    SpeedDial,
    SpeedDialIcon,
    Stack,
    styled,
    Typography,
    useTheme,
} from '@mui/material';
import Editor, { Monaco, OnMount } from '@monaco-editor/react';
import {
    AccessTime,
    CalendarMonth,
    ChevronLeft,
    ChevronRight,
    People,
    Preview,
    Edit,
    Save,
    Height,
} from '@mui/icons-material';
import { DateTime } from 'luxon';
import { readingTimeFromContent } from './documentUtils';
import MarkdownPreview from '@uiw/react-markdown-preview';
import ImageUploader from '../../assets/components/ImageUploader';
import config from '../../config';
import Prism from 'prismjs';
import 'prismjs/plugins/copy-to-clipboard/prism-copy-to-clipboard';
import 'prismjs/plugins/line-numbers/prism-line-numbers';

import './Document.scss';
import '../../assets/styles/prism.css';
import useAuthConfiguration from '../../assets/hooks/useAuthConfiguration';
import parse, { HTMLReactParserOptions, domToReact } from 'html-react-parser';
import MediaPlayer from '../../assets/components/media-player/MediaPlayer';
import Alert, { AlertTypes } from '../../assets/components/alert/Alert';
import Lottie from '../../assets/components/lottie/Lottie';
import authRequired from '../../assets/images/auth-required.json';

const drawerWidth = '40%';

const EditorContainer = styled('main', {
    shouldForwardProp: (prop) => prop !== 'open',
})<{ open?: boolean }>(({ theme, open }) => ({
    flexGrow: 1,
    height: '100%',
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    marginRight: 0,
    ...(open && {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginRight: drawerWidth,
    }),
    display: 'flex',
    flexDirection: 'column',
}));

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-start',
}));

const Document: React.FC = () => {
    const { secret, projectUrl, '*': splat } = useParams();
    const { setAppState } = useContext(AppContext);
    const [isEditMode, setIsEditMode] = useState(false);
    const [previewOpen, setPreviewOpen] = useState(false);
    const [rawContent, setRawContent] = useState('');

    const theme = useTheme();

    //TODO forward to homepage if splat is empty

    const [isEditorReady, setIsEditorReady] = useState(false);
    const editorRef = useRef<any>();

    const handleEditorMount = (ref: any) => {
        setIsEditorReady(true);
        editorRef.current = ref;
    };

    useEffect(() => {
        projectUrl && setAppState((prev: any) => ({ ...prev, projectUrl }));
    }, [setAppState, projectUrl]);

    const authConfig = useAuthConfiguration(secret);
    const args = useMemo(
        () => ({ params: { projectUrl, path: splat }, ...authConfig }),
        [projectUrl, splat, authConfig]
    );
    const {
        data: documentData,
        error,
        mutate,
        isLoading: loading,
    } = useSWR(
        { url: '/api/services/app/Documents/GetDocumentByUrl', args },
        argsFetcher
    );

    const documentRef = useRef<HTMLDivElement | null>();

    const [saving, setIsSaving] = useState(false);
    const isLoading = saving || loading;

    const handleEditSave = useCallback(async () => {
        if (isEditMode) {
            setIsEditMode(false);
            setIsSaving(true);
            let res = false;
            let content = editorRef.current.getValue();
            if (content) {
                res = await updateItem(
                    '/api/services/app/Documents/UpdateAndSyncDocument',
                    { ...documentData, content },
                    authConfig
                );
            }
            mutate();
            setIsSaving(false);
        } else {
            setIsEditMode(true);
        }
    }, [isEditMode]);

    const parseDocument = useCallback((content: string) => {
        const alertTypes = AlertTypes.map((t) => t.toUpperCase());

        const parserOptions: HTMLReactParserOptions = {
            replace: (domNode) => {
                const { name, attribs, children } = domNode as any;

                if (name === 'a' && attribs.href) {
                    // Disable Mermaid edit links when secret is used
                    if (
                        secret &&
                        attribs.href.startsWith(
                            'https://mermaid-js.github.io/mermaid-live-editor'
                        )
                    ) {
                        return <div>{domToReact(children)}</div>;
                    }

                    return (
                        <Link to={attribs.href}>{domToReact(children)}</Link>
                    );
                } else if (name === 'video' && attribs.src) {
                    return <MediaPlayer src={attribs.src} />;
                } else if (name === 'div') {
                    // Alerts
                    if (alertTypes.includes(attribs.class)) {
                        console.log(children);
                        return (
                            <Alert type={attribs.class?.toLowerCase()}>
                                {domToReact(
                                    children.filter((c: any) => c.name === 'p')
                                )}
                            </Alert>
                        );
                    }
                }
            },
        };

        return parse(content, parserOptions);
    }, []);

    const replaceConsts = useCallback((content: string) => {
        if (!content) return content;

        const items = {
            $SERVER_URL: config.serviceUrl,
        };

        Object.keys(items).map(
            (k) => (content = content.replaceAll(k, (items as any)[k]))
        );

        return content;
    }, []);

    const renderedContent = replaceConsts(documentData?.renderedContent);
    const renderedDocument = useMemo(
        () => (renderedContent ? parseDocument(renderedContent) : null),
        [renderedContent, parseDocument]
    );

    useEffect(() => {
        if (!documentRef || documentRef.current == null) return;

        // documentRef.current
        //     .querySelectorAll(".NOTE, .TIP")
        //     .forEach((e) => e.classList.add("alert", "alert-info"));
        // documentRef.current
        //     .querySelectorAll(".WARNING")
        //     .forEach((e) => e.classList.add("alert", "alert-warning"));
        // documentRef.current
        //     .querySelectorAll(".IMPORTANT, .CAUTION")
        //     .forEach((e) => e.classList.add("alert", "alert-danger"));
        // documentRef.current.querySelectorAll('a').forEach((e)=> !e.href.startsWith('http'))
    }, [documentRef, documentData]);

    useEffect(() => {
        !rawContent &&
            documentData?.content &&
            setRawContent(documentData.content);
    }, [documentData, rawContent, setRawContent]);

    useEffect(() => {
        if (!documentData?.renderedContent) return;
        Prism.plugins.CopyButton = true;
        Prism.highlightAll();
    }, [documentData]);

    const readingTime = useMemo(
        () => readingTimeFromContent(documentRef.current?.innerText ?? ''),
        [documentRef]
    );

    const preview = useMemo(
        () => (
            <MarkdownPreview
                source={replaceConsts(rawContent)}
                style={{ height: '100%' }}
            />
        ),
        [rawContent]
    );

    const insertImage = (url: string, altText: string) => {
        if (!editorRef.current) return;

        const editor = editorRef.current;

        const selection = editor.getSelection();
        const id = { major: 1, minor: 1 };
        const text = `![${altText ?? 'image'}](${url})`;
        var op = {
            identifier: id,
            range: selection,
            text,
            forceMoveMarkers: true,
        };
        editor.executeEdits('my-source', [op]);
    };

    return (
        <div
            className="view view--document h-full w-full"
            style={{ position: 'relative' }}
        >
            {error?.response && error.response?.status === 401 && (
                <Grid container spacing={2} direction="column" alignItems="center" justifyContent="center">
                    <Lottie
                        animationData={authRequired}
                        style={{
                            width: 'min(70%,600px)',
                            height: 'min(70%,600px)',
                        }}
                    ></Lottie>
                    <Typography variant="h3" marginTop={3} textAlign="center">Sie benötigen weitere Berechtigungen, um auf dieses Element zuzugreifen.</Typography>
                </Grid>
            )}
            {isEditMode ? (
                <>
                    {documentData && (
                        <>
                            <ImageUploader
                                documentId={documentData.id}
                                projectId={documentData.projectId}
                            />
                            <IconButton
                                onClick={() => setPreviewOpen(true)}
                                sx={{
                                    position: 'absolute',
                                    top: '-1rem',
                                    right: '5rem',
                                    zIndex: theme.zIndex.speedDial,
                                }}
                            >
                                <Preview />
                            </IconButton>
                            <EditorContainer open={previewOpen}>
                                <Stack
                                    className="images flex-grow-0"
                                    direction="row"
                                >
                                    {documentData.mediaElements.map(
                                        (e: any) => (
                                            <img
                                                alt={e.altText}
                                                src={
                                                    config.serviceUrl +
                                                    `/api/media/${documentData.id}/${e.id}`
                                                }
                                                key={e.id}
                                                style={{
                                                    maxHeight: '50px',
                                                    margin: theme.spacing(1),
                                                    flex: 0,
                                                }}
                                                onClick={() =>
                                                    insertImage(
                                                        '$SERVER_URL' +
                                                            `/api/media/${documentData.id}/${e.id}`,
                                                        e.altText
                                                    )
                                                }
                                            />
                                        )
                                    )}
                                </Stack>
                                <div className="flex-1 overflow-hidden">
                                    <Editor
                                        height="100%"
                                        language={documentData.contentType}
                                        value={documentData.content}
                                        onMount={handleEditorMount}
                                        onChange={(e) => setRawContent(e ?? '')}
                                    />
                                </div>
                            </EditorContainer>
                            <Drawer
                                sx={{
                                    width: drawerWidth,
                                    flexShrink: 0,
                                    '& .MuiDrawer-paper': {
                                        width: drawerWidth,
                                    },
                                }}
                                variant="persistent"
                                anchor="right"
                                open={previewOpen}
                            >
                                <DrawerHeader>
                                    <IconButton
                                        onClick={() => setPreviewOpen(false)}
                                    >
                                        {theme.direction === 'rtl' ? (
                                            <ChevronLeft />
                                        ) : (
                                            <ChevronRight />
                                        )}
                                    </IconButton>
                                </DrawerHeader>
                                <Divider />
                                <Box
                                    sx={{
                                        padding: theme.spacing(2),
                                        overflow: 'auto',
                                    }}
                                >
                                    {preview}
                                </Box>
                            </Drawer>
                        </>
                    )}
                </>
            ) : (
                <>
                    {documentData ? (
                        <Box>
                            <Typography variant="h1">
                                {documentData.title}
                            </Typography>
                            <Box>
                                <Stack
                                    className="mt-1 mb-3"
                                    direction="row"
                                    alignItems="center"
                                >
                                    <CalendarMonth className="icon-inline ml-0" />
                                    <Typography variant="caption">
                                        {DateTime.fromISO(
                                            documentData.lastModificationTime ??
                                                documentData.creationTime
                                        ).toLocaleString(DateTime.DATE_SHORT)}
                                    </Typography>
                                    <span className="mx-3">&middot;</span>
                                    <AccessTime className="icon-inline" />
                                    <Typography variant="caption">
                                        {readingTime}
                                    </Typography>
                                    <span className="mx-3">&middot;</span>
                                    <People className="icon-inline" />
                                    <Typography variant="caption">
                                        {'Florian Hiensch'}
                                    </Typography>
                                </Stack>
                            </Box>
                            <LinearProgress
                                className="relative top-1"
                                sx={{
                                    visibility: isLoading
                                        ? 'visible'
                                        : 'hidden',
                                }}
                            />
                            <div
                                className="document-page"
                                // dangerouslySetInnerHTML={{
                                //   __html: documentData.renderedContent,
                                // }}
                                ref={documentRef as any}
                            >
                                {renderedDocument}
                            </div>
                        </Box>
                    ) : null}
                </>
            )}

            {!secret && (
                <SpeedDial
                    ariaLabel="Edit Document"
                    sx={{
                        position: 'fixed',
                        bottom: '5vh',
                        right: '5vw',
                        zIndex: theme.zIndex.drawer + 1,
                    }}
                    icon={
                        <SpeedDialIcon
                            icon={isEditMode ? <Save /> : <Edit />}
                        />
                    }
                    onClick={handleEditSave}
                />
            )}
        </div>
    );
};

export default Document;
