import { useCallback, useEffect, useState } from "react";
import { AxiosRequestConfig } from "axios";
import { useAuthenticator } from "../../data/authentication/Authenticator";

export default function useAuthConfiguration(secret?: string) {
    const { user, refreshToken, isCurrentlyAuthenticated } = useAuthenticator();
    const [authConfig, setAuthConfig] = useState<AxiosRequestConfig<any>>();

    const token = user?.tokenInfo?.token;
    const apiKey = useCallback(() => {
        if (!isCurrentlyAuthenticated() && !secret) {
            refreshToken(abp.session.tenantId ?? null);
            return '';
        }

        return `Bearer ${token}`;
    }, [isCurrentlyAuthenticated, refreshToken, token, secret]);

    useEffect(() => setAuthConfig({ headers: { 'Authorization': apiKey() as any, 'data-secret': secret } }), [setAuthConfig, user, apiKey, secret]);

    return authConfig;
}