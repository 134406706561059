import React, { useEffect, useRef } from 'react';
import lottie from 'lottie-web';

export type LottieProps = {
    animationData: any;
    style?: React.CSSProperties;
};

const Lottie: React.FC<LottieProps> = ({ animationData, style }) => {
    const containerRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (containerRef.current) {
            lottie.destroy();
            lottie.loadAnimation({
                container: containerRef.current, // The div element
                renderer: 'svg', // The renderer type
                loop: true, // Whether to loop the animation
                autoplay: true, // Whether to play the animation automatically
                animationData: animationData, // The JSON data of the animation
            });
        }
    }, [containerRef, animationData]);

    return <div className="lottie" ref={containerRef} style={style}></div>;
};

export default Lottie;
