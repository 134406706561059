import { Avatar, ButtonBase, useTheme } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';
import LogoSection from './LogoSection';
import MenuIcon from '@mui/icons-material/Menu';

const Header: React.FC<{ handleLeftDrawerToggle: () => void }> = ({ handleLeftDrawerToggle }) => {
    const theme = useTheme();

    return (
        <>
            {/* logo & toggler button */}
            <Box
                sx={{
                    width: 380,
                    display: 'flex',
                    [theme.breakpoints.down('md')]: {
                        width: 'auto',
                    },
                }}
            >
                <ButtonBase sx={{ borderRadius: '12px', overflow: 'hidden' }} disableRipple>
                    <Avatar
                        variant="rounded"
                        sx={{
                            ...(theme.typography as any).commonAvatar,
                            ...(theme.typography as any).mediumAvatar,
                            transition: 'all .2s ease-in-out',
                            // background: theme.palette.secondary.light,
                            // color: theme.palette.secondary.dark,
                            // "&:hover": {
                            //     // background: theme.palette.secondary.dark,
                            //     color: theme.palette.secondary.light,
                            // },
                        }}
                        onClick={handleLeftDrawerToggle}
                        color="inherit"
                    >
                        <MenuIcon />
                    </Avatar>
                </ButtonBase>
                <Box component="span" sx={{ display: { xs: 'none', md: 'block' }, flexGrow: 1 }}>
                    <LogoSection />
                </Box>
            </Box>

            {/* header search */}
            {/* <SearchSection /> */}
            <Box sx={{ flexGrow: 1 }} />
            <Box sx={{ flexGrow: 1 }} />

            {/* notification & profile */}
            {/* <NotificationSection />
            <ProfileSection /> */}
        </>
    );
};

export default Header;
