import axios, { AxiosRequestConfig } from 'axios';
import config from '../config';

export const argsFetcher = (req: { url: string; args?: AxiosRequestConfig<any> }) => {
    if (!req) return null;
    const url = req.url.startsWith('http') ? req.url : config.serviceUrl + req.url;
    console.log(req.args);
    return axios.get(url, req.args).then((res) => res.data.result);
};

export const fetcher = (url: string) => {
    if (!url) return null;
    return axios.get(url.startsWith('http') ? url : config.serviceUrl + url).then((res) => res.data.result);
};

export const updateItem = (url: string, data: any, args?: AxiosRequestConfig<any>) => {
    if (!url) return null;
    return axios
        .post(
            url.startsWith('http') ? url : config.serviceUrl + url,
            data,
            args
                // ? {
                //     headers: {
                //         Authorization: `Bearer ${args.token}`,
                //     },
                // }
                // : undefined
        )
        .then((res) => res.data.result);
};

export const uploadFile = async (url: string, file: File, params: any) => {
    if (!url) return null;

    const formData = new FormData();
    formData.append("file", file);
    // formData.append("fileName", file.name);

    const result = await axios.post(
        url.startsWith('http') ? url : config.serviceUrl + url, formData, params ? { params } : undefined);

    return result.data.result;
}