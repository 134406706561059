import React, { useState, useMemo, useCallback, useContext } from 'react';
import axios from 'axios';
import { UserInfo, AuthContextType, LoginResponse, RefreshTokenResponse } from './types';
import { decode, JwtPayload } from 'jsonwebtoken';
import { DateTime } from 'luxon';
import { useNavigate } from 'react-router-dom';

export const KEY_AUTH_INFO = 'authInfo';

export type AuthInfo = {
    name: string;
    tokenInfo: {
        token: string;
        payload: string | JwtPayload | null;
        encryptedToken: string;
        refreshToken: string | undefined;
        expires: any;
    };
};

/**
 * A react context used by Authenticator to make available auth information and methods
 */
const AuthContext = React.createContext<AuthContextType | null>(null);

/**
 * Returns the closest AuthContext, effectively providing information and methods for authentication purposes
 */
export function useAuthenticator(): AuthContextType {
    const ctx = useContext(AuthContext);
    if (ctx === null) throw new Error('No Auth context available');
    return ctx;
}

interface AuthenticatorProps {
    authenticationUrl: string;
    refreshTokenUrl: string;
}

/**
 * A Component which makes available authentication related information and methods using react context
 * @param props The component props (e.g. the URL of the auth endpoint)
 */
const Authenticator: React.FC<React.PropsWithChildren<AuthenticatorProps>> = (props) => {
    const [isAuthenticating, setIsAuthenticating] = useState(false);
    const [authenticationError, setAuthenticationError] = useState<string>();
    const navigate = useNavigate();

    const [user, setUser] = useState<UserInfo | null>(() => {
        const userJson = localStorage.getItem(KEY_AUTH_INFO);
        if (userJson === null) return null;
        const user = JSON.parse(userJson);
        user.tokenInfo.expires = new Date(user.tokenInfo.expires);
        return user;
    });

    function encodeQueryData(data: any) {
        const ret = [];
        for (const d in data) ret.push(encodeURIComponent(d) + '=' + encodeURIComponent(data[d]));
        return ret.join('&');
    }

    /**
     * Allows authentication with an ABP auth endpoint. (POST request using axios)
     */
    const authenticate = useCallback( 
        async (tenantId: number | null, user: string, password: string, rememberClient: boolean) => {
            const loginPayload = {
                UserNameOrEmailAddress: user,
                Password: password,
                rememberClient: rememberClient ?? false,
            };
            const headers = tenantId
                ? {
                      'Abp.TenantId': tenantId,
                  }
                : undefined;
            setIsAuthenticating(true);
            setAuthenticationError(undefined);
            
            const response = await axios
            .post<LoginResponse>(props.authenticationUrl, loginPayload, {
                headers,
            });

            try {
                if (!response.data.success || !response.data.result) {
                    throw response.data.error;
                }
                setIsAuthenticating(false);
                const userInfo: AuthInfo = {
                    name: user,
                    tokenInfo: {
                        token: response.data.result.accessToken,
                        payload: response.data.result.accessToken && decode(response.data.result.accessToken),
                        encryptedToken: response.data.result.encryptedAccessToken!,
                        refreshToken: rememberClient ? response.data.result.refreshToken! : undefined,
                        expires: DateTime.now().plus({ seconds: response.data.result.expireInSeconds }).toJSDate(),
                    },
                };
                setUser(userInfo);
                localStorage.setItem(KEY_AUTH_INFO, JSON.stringify(userInfo));    
            } catch (err) {
                setIsAuthenticating(false);
                setAuthenticationError('Failure');                  
            }                        
                                              
        },
        [setUser, props.authenticationUrl]
    );

    /**
     * Allows authentication with an ABP auth endpoint using a refresh token. (POST request using axios)
     */
    const refreshToken = useCallback(
        (tenantId: number | null) => {
            const userInfo: AuthInfo = JSON.parse(localStorage.getItem(KEY_AUTH_INFO) ?? '{}');
            if (!userInfo?.tokenInfo?.refreshToken) {
                setIsAuthenticating(false);
                setAuthenticationError('Failure');
                navigate('/login');
            }

            const loginPayload = {
                refreshToken: userInfo?.tokenInfo?.refreshToken,
            };

            const headers = tenantId
                ? {
                      'Abp.TenantId': tenantId,
                  }
                : undefined;
            setIsAuthenticating(true);
            setAuthenticationError(undefined);
            axios
                .post<RefreshTokenResponse>(props.refreshTokenUrl, loginPayload, {
                    headers,
                })
                .then((response) => {
                    if (!response.data.success || !response.data.result) {
                        throw response.data.error;
                    }
                    setIsAuthenticating(false);
                    const newUserInfo: AuthInfo = {
                        ...userInfo,
                        tokenInfo: {
                            ...userInfo?.tokenInfo,
                            token: response.data.result.accessToken,
                            payload: response.data.result.accessToken && decode(response.data.result.accessToken),
                            encryptedToken: response.data.result.encryptedAccessToken!,
                            expires: DateTime.now().plus({ seconds: response.data.result.expireInSeconds }).toJSDate(),
                        },
                    };
                    setUser(newUserInfo);
                    localStorage.setItem(KEY_AUTH_INFO, JSON.stringify(newUserInfo));
                })
                .catch((err) => {
                    setIsAuthenticating(false);
                    setAuthenticationError('Failure');
                    navigate('/login');
                });
        },
        [setUser, props.authenticationUrl]
    );

    /**
     * Allows logging out by erasing the auth token from local storage
     * There is no action to be taken on the server, as we are using self contained JWTs
     */
    const logout = useCallback(() => {
        setUser(null);
        localStorage.removeItem(KEY_AUTH_INFO);
    }, [setUser]);

    /**
     * Specifies if a user is currently authenticated => user not null AND token not expired
     */
    const isCurrentlyAuthenticated = useCallback(() => {
        return user !== null ? user?.tokenInfo?.expires > new Date() : false;
    }, [user]);

    /**
     * The current auth state, memoized to make sure to only generate new objects if needed
     */
    const authInfo = useMemo<AuthContextType>(() => {
        const info: AuthContextType = {
            isAuthenticated: user !== null ? user?.tokenInfo?.expires > new Date() : false,
            isCurrentlyAuthenticated,
            authenticate,
            refreshToken,
            isAuthenticating,
            authenticationError,
            logout,
        };

        if (user !== null) info.user = user;

        return info;
    }, [authenticate, refreshToken, logout, user, isAuthenticating, authenticationError, isCurrentlyAuthenticated]);

    return <AuthContext.Provider value={authInfo}>{props.children}</AuthContext.Provider>;
};

export default Authenticator;
