import { Pause, PlayArrow } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import React, { useCallback } from "react";
import { withMediaProps } from "react-media-player";

const PlayPauseButton: React.FC = (props: any) => {
    const handlePlayPause = useCallback(() => {
        props.media.playPause();
    }, []);

    const { className, style, media } = props;
    return (
        <IconButton onClick={handlePlayPause} color="secondary">
            {media.isPlaying ? <Pause /> : <PlayArrow />}
        </IconButton>
    );
};

export default withMediaProps(PlayPauseButton);
