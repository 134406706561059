import { Label } from '@mui/icons-material';
import { Avatar, Chip, ListItemButton, ListItemIcon, ListItemText, Typography, useMediaQuery, useTheme } from '@mui/material';
import React, { forwardRef, Suspense, useMemo } from 'react';
import { Link } from 'react-router-dom';
import * as MuiIcons from '@mui/icons-material/';

export type SideNavItem = {
    id: string;
    title: string;
    type: 'group' | 'item' | 'collapse';
    caption?: string;
    target?: string;
    url?: string;
    disabled?: boolean;
    children?: SideNavItem[];
    icon?: React.ReactNode;
    //   chip?:{ }
};

const defaultIcon = (level: number) => (
    <Label
        // sx={{
        //     width: customization.isOpen.findIndex((id) => id === item?.id) > -1 ? 8 : 6,
        //     height: customization.isOpen.findIndex((id) => id === item?.id) > -1 ? 8 : 6
        // }}
        fontSize={level > 0 ? 'inherit' : 'medium'}
    />
);

const NavItem: React.FC<{ item: any; level: number }> = ({ item, level }) => {
    const theme = useTheme();
    const matchesSM = useMediaQuery(theme.breakpoints.down('lg'));

    const iconName = item?.icon;
    iconName && console.log((MuiIcons as any)[iconName]);
    const itemIcon = useMemo(() => {
        if (iconName) {
            if (typeof iconName === 'string') {
                const Icon = MuiIcons[iconName as keyof typeof MuiIcons];
                return <Icon />;
            }
            return [iconName];
        }

        return defaultIcon(level);
    }, [iconName, level]);

    let itemTarget = '_self';
    if (item.target) {
        itemTarget = '_blank';
    }

    let listItemProps = {
        component: forwardRef((props, ref) => <Link ref={ref as any} {...props} to={item.url} target={itemTarget} />),
    };

    return (
        <ListItemButton
            {...listItemProps}
            disabled={item.disabled}
            sx={{
                mb: 0.5,
                alignItems: 'flex-start',
                backgroundColor: level > 1 ? 'transparent !important' : 'inherit',
                py: level > 1 ? 1 : 1.25,
                pl: `${level * 24}px`,
            }}
        >
            <ListItemIcon sx={{ my: 'auto', minWidth: !item?.icon ? 18 : 36 }}>{itemIcon}</ListItemIcon>
            <ListItemText
                primary={
                    <Typography
                        // variant={
                        //   customization.isOpen.findIndex((id) => id === item.id) > -1
                        //     ? "h5"
                        //     : "body1"
                        // }
                        color="inherit"
                    >
                        {item.title}
                    </Typography>
                }
                secondary={
                    item.caption && (
                        <Typography variant="caption" sx={{ ...(theme.typography as any).subMenuCaption }} display="block" gutterBottom>
                            {item.caption}
                        </Typography>
                    )
                }
            ></ListItemText>
            {/* {item.chip && (
        <Chip
          color={item.chip.color}
          variant={item.chip.variant}
          size={item.chip.size}
          label={item.chip.label}
          avatar={item.chip.avatar && <Avatar>{item.chip.avatar}</Avatar>}
        />
      )} */}
        </ListItemButton>
    );
};

export default NavItem;
