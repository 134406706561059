// import messagesDe from '../common/localization/kendo.de-DE.json';
// import { load, loadMessages } from '@progress/kendo-react-intl';
// import likelySubtags from 'cldr-core/supplemental/likelySubtags.json';
// import currencyData from 'cldr-core/supplemental/currencyData.json';
// import weekData from 'cldr-core/supplemental/weekData.json';
// import deLocalCurrency from 'cldr-numbers-full/main/de/currencies.json';
// import deNumbers from 'cldr-numbers-full/main/de/numbers.json';
// import deCaGregorian from 'cldr-dates-full/main/de/ca-gregorian.json';
// import deDateFields from 'cldr-dates-full/main/de/dateFields.json';

import config from "../config";

export function L(key: string, sourceName?: string): string {
  const localizationSourceName = config.localization.defaultLocalizationSourceName;
  return abp.localization.localize(key, sourceName ? sourceName : localizationSourceName);
}

export const isGranted = (permissionName: string): boolean => abp.auth.isGranted(permissionName);

export function loadScript(url: string): void {
  const script = document.createElement('script');
  script.type = 'text/javascript';
  script.src = url;
  document.body.appendChild(script);
}

export function extend(...args: any[]): any {
  let options;
  let name;
  let src;
  let srcType;
  let copy;
  let copyIsArray;
  let clone;
  let target = args[0] || {};
  let i = 1;
  const length = args.length;
  let deep = false;
  if (typeof target === 'boolean') {
    deep = target;
    target = args[i] || {};
    i++;
  }
  if (typeof target !== 'object' && typeof target !== 'function') {
    target = {};
  }
  if (i === length) {
    target = null;
    i--;
  }
  for (; i < length; i++) {
    options = args[i];
    if (options !== null && options !== undefined) {
      // eslint-disable-next-line guard-for-in
      for (name in options) {
        src = target[name];
        copy = options[name];
        if (target === copy) {
          continue;
        }
        srcType = Array.isArray(src) ? 'array' : typeof src;
        if (deep && copy && ((copyIsArray = Array.isArray(copy)) || typeof copy === 'object')) {
          if (copyIsArray) {
            copyIsArray = false;
            clone = src && srcType === 'array' ? src : [];
          } else {
            clone = src && srcType === 'object' ? src : {};
          }
          target[name] = extend(deep, clone, copy);
        } else if (copy !== undefined) {
          target[name] = copy;
        }
      }
    }
  }

  return target;
}

// getPageTitle = (pathname: string) => {
//   const route = routers.filter(route => route.path === pathname);
//   const localizedAppName = L('AppName');
//   if (!route || route.length === 0) {
//     return localizedAppName;
//   }

//   return L(route[0].title) + ' | ' + localizedAppName;
// };

// getRoute = (path: string): any => {
//   return routers.filter(route => route.path === path)[0];
// };

export function setLocalization(): void {
  if (!abp.utils.getCookieValue('Abp.Localization.CultureName')) {
    const language = navigator.language;
    abp.utils.setCookieValue('Abp.Localization.CultureName', language, new Date(new Date().getTime() + 5 * 365 * 86400000), abp.appPath);
  }
}

// export function loadLocalizationFiles(): void {
//   const lang = abp?.localization?.currentLanguage?.name;

//   console.log("lang is", lang)
//   if (lang && lang === 'de') {
//     loadMessages(messagesDe, 'de');

//     load(
//       likelySubtags,
//       currencyData,
//       weekData,
//       deNumbers,
//       deLocalCurrency,
//       deCaGregorian,
//       deDateFields,
//     );
//     console.log("intl messages loaded")
//   }

// }

export function getCurrentClockProvider(currentProviderName: string): abp.timing.IClockProvider {
  if (currentProviderName === 'unspecifiedClockProvider') {
    return abp.timing.unspecifiedClockProvider;
  }

  if (currentProviderName === 'utcClockProvider') {
    return abp.timing.utcClockProvider;
  }

  return abp.timing.localClockProvider;
}
