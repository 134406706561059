import { ButtonBase, Divider, SvgIcon, Typography, useTheme } from '@mui/material';
import React from 'react';
import { Link } from 'react-router-dom';
import { ReactComponent as OrvLogo } from '../../../assets/images/orv-logo.svg';
import config from '../../../config';

const LogoSection = () => {
    // const defaultId = useSelector((state) => state.customization.defaultId);
    // const dispatch = useDispatch();
    const theme = useTheme();

    return (
        <ButtonBase
            sx={{
                height: '100%',
                width: '100%',
                padding: theme.spacing(2),
            }}
            disableRipple
            /*onClick={() => dispatch({ type: MENU_OPEN, id: defaultId })}*/ component={Link}
            to={config.defaultPath}
        >
            {/* <SvgIcon component={OrvLogo} inheritViewBox /> */}
            <OrvLogo />
            &emsp;
            <Divider orientation="vertical" />
            &emsp;
            <Typography variant="h3" sx={{ flex: '1 0 auto' }}>
                Docs
            </Typography>
        </ButtonBase>
    );
};

export default LogoSection;
