import { SideNavItem } from "../main-layout/sidebar/menu-list/NavItem";
import Dashboard from '@mui/icons-material/Dashboard';

const main: SideNavItem = {
    id:'main',
    title:'Home',
    type:'group',
    children:[
        {
            id:'default',
            title:'Start',
            type:'item',
            url:'/',
            icon: <Dashboard/>
        }
    ]
}

export default main;