import { PopoverPosition, PopoverReference } from '@mui/material';
import React from 'react';

const useContextMenu = () => {

    const [contextMenu, setContextMenu] = React.useState<{
        mouseX: number;
        mouseY: number;
    } | null>(null);

    const handleContextMenu = (event: React.MouseEvent) => {
        event.preventDefault();
        setContextMenu(
            contextMenu === null
                ? {
                    mouseX: event.clientX + 2,
                    mouseY: event.clientY - 6,
                }
                : // repeated contextmenu when it is already open closes it with Chrome 84 on Ubuntu
                // Other native context menus might behave different.
                // With this behavior we prevent contextmenu from the backdrop to re-locale existing context menus.
                null,
        );
    };

    const handleClose = () => {
        setContextMenu(null);
    };

    return {
        openContextMenu: handleContextMenu,
        menuProps: {
            open: contextMenu !== null,
            onClose: handleClose,
            anchorReference: "anchorPosition" as PopoverReference,
            anchorPosition: (
                contextMenu !== null
                    ? {
                        top: contextMenu.mouseY,
                        left: contextMenu.mouseX,
                    }
                    : undefined
            )
        }
    };
}

export default useContextMenu;