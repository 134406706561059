import { Add } from '@mui/icons-material';
import { Button, Dialog, DialogActions, DialogTitle, FormControl, FormHelperText, Input, InputLabel, Stack, useTheme } from '@mui/material';
import { DateTime } from 'luxon';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { updateItem, uploadFile } from '../../data/fetcher';

const ImageUploader: React.FC<{ projectId: number; documentId: string }> = ({ projectId, documentId }) => {
    const theme = useTheme();
    const [isOpen, setIsOpen] = useState(false);
    const [imageData, setImageData] = useState('');
    const [imageBlob, setImageBlob] = useState<File | undefined>();
    const [fileResult, setFileResult] = useState<FileList | undefined>();
    const imageElementRef = useRef<HTMLImageElement>();
    const inputElementRef = useRef<HTMLInputElement>();

    const [formState, setFormState] = useState({ filename: 'screenshot', description: undefined, alttext: undefined });
    const { filename, alttext, description } = formState;

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setFormState((state) => ({ ...state, [e.target.id]: e.target.value }));
    };

    const handlePaste = useCallback((e: ClipboardEvent) => {
        const clipboardItems = e?.clipboardData?.items;
        const items: DataTransferItemList = [].slice.call(clipboardItems).filter((item: any) => /^image\//.test(item.type)) as any;

        if (items.length === 0) {
            return;
        }

        const item = items[0];
        const blob = item.getAsFile();

        if (!blob) return;

        setImageData(URL.createObjectURL(blob));
        setImageBlob(blob);
        setIsOpen(true);
    }, []);

    const updateImage = useCallback(() => {
        if (!imageData || !imageElementRef.current) return;
        imageElementRef.current.src = imageData;
    }, [imageData]);

    const imageRef = useCallback(
        (node: HTMLImageElement) => {
            imageElementRef.current = node;
            updateImage();
        },
        [updateImage]
    );

    useEffect(() => {
        updateImage();
    }, [updateImage]);

    const updateFile = useCallback(() => {
        if (!imageBlob || !filename /* || !inputElementRef.current*/) return;
        let file = new File([imageBlob], filename.replace(/\s/g, '-'), {
            type: imageBlob.type ?? 'image/jpeg',
            lastModified: new Date().getTime(),
        });
        let container = new DataTransfer();
        container.items.add(file);
        // inputElementRef.current.files = container.files;
        setFileResult(container.files);
    }, [imageBlob, filename]);

    // const inputRef = useCallback(
    //     (node: HTMLInputElement) => {
    //         inputElementRef.current = node;
    //         updateFile();
    //     },
    //     [updateFile]
    // );

    useEffect(() => {
        updateFile();
    }, [updateFile]);

    useEffect(() => {
        handlePaste && document.addEventListener('paste', handlePaste);

        return () => {
            document.removeEventListener('paste', handlePaste);
        };
    }, [handlePaste]);

    const handleClose = () => {
        setIsOpen(false);
        setImageData('');
        setImageBlob(undefined);
        setFileResult(undefined);

        setFormState({ filename: 'screenshot', description: undefined, alttext: undefined });
    };

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (!fileResult) return;

        const id = await updateItem('/api/services/app/Media/CreateMediaElement', {
            projectId,
            documentId,
            isImage: true,
            file: {
                fileName: filename,
                description: description,
                altText: alttext,
                contentType: fileResult[0].type,
            },
        });

        const success = await uploadFile('/api/services/app/Media/Upload', fileResult[0], { elementId: id });

        success && handleClose();
    };

    return (
        <div className="image-uploader">
            <Dialog open={isOpen} onClose={handleClose}>
                <DialogTitle>Grafik hinzufügen</DialogTitle>
                <Stack direction="column" alignItems="center" justifyContent="center" sx={{ padding: '1rem' }}>
                    <img alt="" ref={imageRef} style={{ margin: '1rem', maxWidth: 600, maxHeight: 'calc(100vh - 500px)' }}></img>
                    <form onSubmit={handleSubmit} style={{ margin: '1rem' }}>
                        <Stack direction="column">
                            <FormControl sx={{ ...(theme.typography as any).customInput }}>
                                <InputLabel htmlFor="filename">Dateiname</InputLabel>
                                <Input
                                    id="filename"
                                    type="text"
                                    autoFocus={true}
                                    defaultValue={'screenshot'}
                                    value={filename}
                                    onChange={handleChange}
                                    aria-describedby="filename-text"
                                />
                                {/* <FormHelperText id="filename-text">Optional, zur Unterscheidbarkeit der Dateien</FormHelperText> */}
                            </FormControl>
                            <FormControl sx={{ ...(theme.typography as any).customInput }}>
                                <InputLabel htmlFor="alttext">Alternativtext</InputLabel>
                                <Input id="alttext" type="text" value={alttext} onChange={handleChange} aria-describedby="alttext-text" />
                                {/* <FormHelperText id="alttext-text">Optional, Beschreibung des Bildes</FormHelperText> */}
                            </FormControl>
                            <FormControl sx={{ ...(theme.typography as any).customInput }}>
                                <InputLabel htmlFor="description">Beschreibung</InputLabel>
                                <Input id="description" type="text" value={description} onChange={handleChange} aria-describedby="description-text" />
                                {/* <FormHelperText id="description-text">Optional, interne Beschreibung</FormHelperText> */}
                            </FormControl>
                            {/* <Input type="file" inputRef={inputRef} sx={{ marginTop: '.5rem' }} /> */}
                            <DialogActions sx={{ marginTop: '1rem' }}>
                                <Button variant="outlined" onClick={() => handleClose()}>
                                    Abbrechen
                                </Button>
                                <Button type="submit" variant="contained" endIcon={<Add />}>
                                    Erstellen
                                </Button>
                            </DialogActions>
                        </Stack>
                    </form>
                </Stack>
            </Dialog>
        </div>
    );
};

export default ImageUploader;
