import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Paper,
  Typography,
} from "@mui/material";
import React, { useContext, useEffect } from "react";
import useSWR from "swr";
import { AppContext } from "../../App";
import config from "../../config";
import { argsFetcher, fetcher } from "../../data/fetcher";
import { useParams } from "react-router-dom";
import useAuthConfiguration from "../../assets/hooks/useAuthConfiguration";
import Grid from "@mui/material/Unstable_Grid2";

const Home: React.FC = () => {
  // Reset project data
  const { setAppState } = useContext(AppContext);

  const { secret, "*": splat } = useParams();

  useEffect(() => {
    setAppState((prev: any) => ({ ...prev, projectUrl: undefined }));
  }, [setAppState]);

  const args = React.useMemo(
    () => ({ params: { projectName: "rewind365", pageName: "index" } }),
    []
  );

  const authConfig = useAuthConfiguration(secret);
  const { data: projectData } = useSWR(
    {
      url: config.serviceUrl + "/api/services/app/Projects/GetAll",
      args: authConfig,
    },
    argsFetcher
  );

  // console.log(data);
  return (
    <div className="view view--home">
      <Grid container spacing={2}>
        {projectData?.items ? (
          projectData.items.map((p: any) => {
            return (
              <Grid xs={4}>
                <Card>
                  <Paper elevation={2}>
                    {p.headerImg && (
                      <CardMedia
                        sx={{ height: 140 }}
                        image={p.headerImg}
                        title={p.name}
                      />
                    )}
                    <CardContent>
                      <Typography gutterBottom variant="h5" component="div">
                        {p.name}
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        {p.description}
                      </Typography>
                    </CardContent>
                    <CardActions>
                      <Button
                        size="small"
                        href={`${
                          secret && secret.length ? `${secret}/` : ""
                        }docs/${p.baseUrl}/${p.homepageUrl}`}
                      >
                        Starten
                      </Button>
                    </CardActions>
                  </Paper>
                </Card>
              </Grid>
            );
          })
        ) : (
          <Typography variant="h4">
            Keine Dokumentationen gefunden, bitte überprüfen Sie Ihre
            Berechtigungen
          </Typography>
        )}
      </Grid>
    </div>
  );
};

export default Home;
