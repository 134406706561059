const config = {
    // basename: only at build time to set, and Don't add '/' at end off BASENAME for breadcrumbs, also Don't put only '/' use blank('') instead,
    // like '/berry-material-react/react/default'
    userManagement: {
        defaultAdminUserName: 'admin'
    },
    localization: {
        defaultLocalizationSourceName: 'DocsUI'
    },
    authorization: {
        encrptedAuthTokenName: 'enc_auth_token'
    },
    appTitle: process.env.REACT_APP_TITLE ?? 'Orgavision Docs',
    appBaseUrl: process.env.REACT_APP_APP_BASE_URL,
    remoteServiceBaseUrl: process.env.REACT_APP_REMOTE_SERVICE_BASE_URL,
    basename: '',
    defaultPath: '',
    fontFamily: `'Roboto', sans-serif`,
    borderRadius: 12,
    serviceUrl: process.env.REACT_APP_REMOTE_SERVICE_BASE_URL
};

export default config;