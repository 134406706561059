import { Stack, Typography } from '@mui/material';
import * as React from 'react';
import { Link } from 'react-router-dom';

const AuthFooter: React.FC = () => (
    <Stack direction="row" justifyContent="space-between">
        <Typography variant="subtitle2" {...{ component: Link, href: 'https://docs.orga.vision', target: '_blank', underline: 'hover' }}>
            Orgavision Docs
        </Typography>
        <Typography variant="subtitle2" {...{ component: Link, href: 'https://orgavision-nav.com', target: '_blank', underline: 'hover' }}>
            &copy; Orgavision S.à r.l.
        </Typography>
    </Stack>
);

export default AuthFooter;
