import { Typography } from "@mui/material";
import React, { useContext, useEffect } from "react";
import { argsFetcher } from "../../../../data/fetcher";
import menu from "./menu-items";
import NavGroup from "./NavGroup";
import { SideNavItem } from "./NavItem";
import useSWR from "swr";
import { useParams } from "react-router-dom";
import { AppContext } from "../../../../App";

const mapDocuments = (items: any[], basePath: string): SideNavItem[] => {
  return items?.map(
    (d) =>
      ({
        id: d.id,
        title: d.menuName ?? d.displayName ?? d.title,
        url: `${basePath}/${d.path}`,
        icon: d.icon,
        type: "item",
      } as SideNavItem)
  );
};

const mapDirectories = (
  items: any[],
  basePath: string,
  secret?: string
): SideNavItem[] => {
  const mappedItems = items.map(
    (d) =>
      ({
        id: d.id,
        title: d.menuName ?? d.name,
        url: d.url,
        type: "item",
      } as SideNavItem)
  );

  let resultItems = mappedItems.slice(); //.filter(i=>items.filter(x=>!!x.parentId).map(x=>x.id).includes(i.id));
  // const otherItems  = mappedItems.slice().filter(i=>!resultItems.map(x=>x.id).includes(i.id));

  for (let item of mappedItems) {
    const resultItem = resultItems.find((i) => i.id === item.id);
    if (!resultItem) continue;

    const parentId = items.find((i) => i.id === item.id).parentId;
    if (!parentId) {
      resultItem.type = "group";
      resultItem.url = `${basePath}/${resultItem.url}`;
      continue;
    }

    const parentItem = resultItems.find((i) => i.id === parentId);
    if (!parentItem) continue;

    resultItem.url = parentItem.url?.startsWith(basePath)
      ? `${parentItem.url}/${resultItem.url}`
      : `${basePath}/${parentItem.url}/${resultItem.url}`;
    resultItem?.children?.push(resultItem);
    resultItems = resultItems.splice(resultItems.indexOf(resultItem), 1);
  }

  for (let result of resultItems) {
    // if ((result.children?.length ?? 0) > 0) result.type = "collapse";
    const item = items.find((i) => i.id === result.id);
    result.children = mapDocuments(item.documents, result.url ?? "");
  }

  return resultItems;
};

const MenuList: React.FC = () => {
  const { appState } = useContext(AppContext);
  const { projectUrl } = appState;
  const staticMenuItems = menu.items;

  const [menuItems, setMenuItems] =
    React.useState<SideNavItem[]>(staticMenuItems);

  console.log("Fetch directories", appState, projectUrl);

  const { data: directoryData } = useSWR(
    projectUrl
      ? {
          url: "/api/services/app/Directory/GetAllByProject",
          args: { params: { projectUrl } },
        }
      : null,
    argsFetcher
  );

  // const { data: documentData } = useSWR(config.serviceUrl + '/api/services/app/Document/GetAll', fetcher);

  const { secret, "*": splat } = useParams();

  useEffect(() => {
    if (!directoryData) return;

    setMenuItems([
      ...staticMenuItems,
      ...mapDirectories(
        directoryData.items.sort((a: any, b: any) =>
          a.index > b.index ? 1 : -1
        ),
        `${secret === undefined ? "" : `/view/${secret}`}/docs/${projectUrl}`
      ),
    ]);
  }, [directoryData, staticMenuItems]);

  const navItems = menuItems.map((item) => {
    switch (item.type) {
      case "group":
        return <NavGroup key={item.id} item={item} />;
      default:
        return (
          <Typography key={item.id} variant="h6" color="error" align="center">
            Menu Items Error
          </Typography>
        );
    }
  });

  return <>{navItems}</>;
};

export default MenuList;
