import { Info, Warning, WarningAmber } from '@mui/icons-material';
import { Box, Stack, useTheme } from '@mui/material';
import React, { PropsWithChildren } from 'react';
import './Alert.scss';

type AlertProps = {
    type: 'note' | 'tip' | 'important' | 'caution' | 'warning';
};

export const AlertTypes = ['Note', 'Tip', 'Important', 'Caution', 'Warning'];

const localizeTitle = (type: string) => {
    // TODO get language
    switch (type) {
        case 'note':
            return 'Hinweis';
        case 'tip':
            return 'Tipp';
        case 'important':
            return 'Wichtig';
        case 'caution':
            return 'Achtung';
        case 'Warning':
            return 'warning';
        default:
            return '';
    }
};

const getIcon = (type: string) => {
    // TODO get language
    switch (type) {
        case 'note':
            return <Info color="info" />;
        case 'tip':
            return <Info color="info" />;
        case 'important':
            return <WarningAmber color="error" />;
        case 'caution':
            return <Warning color="error" />;
        case 'Warning':
            return <WarningAmber color="warning" />;
        default:
            return '';
    }
};

const getColorType = (type: string) => {
    // TODO get language
    switch (type) {
        case 'note':
        case 'tip':
            return 'info';
        case 'important':
        case 'caution':
            return 'danger';
        case 'Warning':
            return 'warning';
        default:
            return '';
    }
};

const Alert: React.FC<PropsWithChildren<AlertProps>> = ({ children, type }) => {
    const theme = useTheme();

    return (
        <div className={`alert alert-${getColorType(type)}`}>
            <h5>
                <Stack direction="row" alignItems="center">
                    <Box display="inline" sx={{ margin: theme.spacing(1) }}>
                        {getIcon(type)}
                    </Box>
                    {localizeTitle(type)}
                </Stack>
            </h5>
            <p>{children}</p>
        </div>
    );
};

export default Alert;
