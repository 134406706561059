import { AxiosResponse } from 'axios';
import { httpClient } from './httpClient';

export const getUserConfiguration = (): Promise<AxiosResponse<any>> => httpClient.get('/AbpUserConfiguration/GetAll');
export const getTenantUserConfiguration = (tenantId: number): Promise<AxiosResponse<any>> => {
    return httpClient.get('/AbpUserConfiguration/GetAll', {
        headers: {
            "Abp.TenantId": tenantId
        }
    });
}
export const getAuthorizedUserConfiguration = (authToken: string): Promise<AxiosResponse<any>> => {
    console.log(authToken);
    return httpClient.get('/AbpUserConfiguration/GetAll', {
        headers: {
            "authorization": `bearer ${authToken}`
        }
    });
}

export const getUserProfilePicture = (authToken: string): Promise<AxiosResponse<any>> => httpClient.get('/api/services/app/Profile/GetProfilePicture', {
    headers: {
        "authorization": `bearer ${authToken}`
    }
})

export type SessionInformation = {

    "result": {

        "user": {
            "name": string,
            "surname": string,
            "userName": string,
            "emailAddress": string,
            "profilePictureId": string,
            "id": number
        },
        "tenant": {
            "tenancyName": string,
            "name": string,
            "logoId": string,
            "logoFileType": string,
            "customCssId": string,
            "subscriptionEndDateUtc": Date,
            "isInTrialPeriod": boolean,
            "subscriptionPaymentType": number,
            "edition": {
                "displayName": string,
                "trialDayCount": number,
                "monthlyPrice": number,
                "annualPrice": number,
                "isHighestEdition": boolean,
                "isFree": boolean,
                "id": number
            },
            "creationTime": Date,
            "paymentPeriodType": number,
            "subscriptionDateString": string,
            "creationTimeString": string,
            "id": number
        },
        "application": {
            "version": string,
            "releaseDate": Date,
            "currency": string,
            "currencySign": string,
            "allowTenantsToChangeEmailSettings": boolean,
            "features": {
                "additionalProp1": boolean,
                "additionalProp2": boolean,
                "additionalProp3": boolean
            }
        },
        "theme": {
            "baseSettings": {
                "theme": string,
                "layout": {
                    "layoutType": string
                },
                "header": {
                    "desktopFixedHeader": boolean,
                    "mobileFixedHeader": boolean,
                    "headerSkin": string,
                    "minimizeDesktopHeaderType": string,
                    "headerMenuArrows": boolean
                },
                "subHeader": {
                    "fixedSubHeader": boolean,
                    "subheaderStyle": string
                },
                "menu": {
                    "position": string,
                    "asideSkin": string,
                    "fixedAside": boolean,
                    "allowAsideMinimizing": boolean,
                    "defaultMinimizedAside": boolean,
                    "submenuToggle": string
                },
                "footer": {
                    "fixedFooter": boolean
                }
            },
            "isLeftMenuUsed": boolean,
            "isTopMenuUsed": boolean,
            "isTabMenuUsed": boolean,
            "allowMenuScroll": boolean
        }
    }
}

export const getUserSessionInformations = (authToken: string): Promise<AxiosResponse<SessionInformation>> => httpClient.get('/api/services/app/Session/GetCurrentLoginInformations', {
    headers: {
        "authorization": `bearer ${authToken}`
    }
})
