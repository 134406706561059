import { Slider } from "@mui/material";
import React, { useCallback, useRef } from "react";
import { withMediaProps } from "react-media-player";

const SeekBarSlider: React.FC = (props: any) => {
    const { media } = props;

    const { duration, currentTime } = media;

    const widthString = "min(10%, 10rem)";

    const isPlayingOnMouseDown = useRef(false);

    const handleMouseDown = useCallback(() => {
        isPlayingOnMouseDown.current = media.isPlaying;
        media.pause();
    }, [media, isPlayingOnMouseDown]);

    const handleChange = useCallback(
        (e: Event, value: number) => {
            media.seekTo(+value);
        },
        [media]
    );

    return (
        <Slider
            style={{
                width: widthString,
                minWidth: widthString,
                flex: 1,
                margin: "0 2rem",
            }}
            color="secondary"
            aria-label="Progress"
            value={currentTime}
            min={0}
            max={duration}
            step={0.01}
            onChange={(e, v) => handleChange(e, v as number)}
        />
    );
};

export default withMediaProps(SeekBarSlider);
