import React from "react";
import {
    createTheme,
    LinkProps,
    PaletteColorOptions,
    ThemeOptions,
} from "@mui/material";
import componentStyleOverrides from "./compStyleOverride";
import colors from "../assets/scss/_themes-vars.module.scss";
import themeTypography from "./typography";
import {
    Link as RouterLink,
    LinkProps as RouterLinkProps,
} from "react-router-dom";

export type Customizations = ThemeOptions & {};

const LinkBehavior = React.forwardRef<
    HTMLAnchorElement,
    Omit<RouterLinkProps, "to"> & { href: RouterLinkProps["to"] }
>((props, ref) => {
    const { href, ...other } = props;
    // Map href (MUI) -> to (react-router)
    return <RouterLink ref={ref} to={href} {...other} />;
});

const loadPalette = (part: string): PaletteColorOptions => {
    const palette = {} as any;
    const properties = [
        "light",
        "main",
        "dark",
        "contrastText",
        "100",
        "200",
        "300",
        "400",
        "500",
        "600",
        "700",
        "800",
        "900",
    ];

    for (const prop of properties) {
        const colorName = `${part}${
            prop.charAt(0).toUpperCase() + prop.substring(1)
        }`;
        if (Object.hasOwn(colors, colorName)) {
            palette[prop] = colors[colorName];
        }
    }

    return palette as PaletteColorOptions;
};

export const appTheme = (customizations: Customizations) => {
    const color = colors;

    const themeOption = {
        colors: color,
        heading: color.grey900,
        paper: color.paper,
        backgroundDefault: color.paper,
        background: color.primaryLight,
        darkTextPrimary: color.grey700,
        darkTextSecondary: color.grey500,
        textDark: color.grey900,
        menuSelected: color.secondaryDark,
        menuSelectedBack: color.secondaryLight,
        divider: color.grey200,
    };

    const themeOptions: ThemeOptions = {
        direction: "ltr",
        typography: themeTypography(themeOption),
        mixins: {
            toolbar: {
                minHeight: "48px",
                padding: "16px",
                "@media (min-width: 600px)": {
                    minHeight: "48px",
                },
            },
        },
        ...customizations,
        palette: {
            primary: loadPalette("primary"),
            secondary: loadPalette("secondary"),
            success: loadPalette("success"),
            error: loadPalette("error"),
            warning: loadPalette("warning"),        
            ...customizations.palette,
        },
        components: {
            MuiLink: {
                defaultProps: {
                    component: LinkBehavior,
                } as LinkProps,
            },
            MuiButtonBase: {
                defaultProps: {
                    LinkComponent: LinkBehavior,
                },
            },
            ...customizations.components,
        },
    };

    console.log(themeOptions);

    const themes = createTheme(themeOptions);
    themes.components = componentStyleOverrides(themeOption);

    return themes;
};

export default appTheme;
